@use "@angular/material" as mat;

$custom-typography: mat.m2-define-typography-config(
    $font-family: "Lato, sans-serif",
    $headline-1: mat.m2-define-typography-level(96px, 96px, 300, $letter-spacing: -1.5px),
    $headline-2: mat.m2-define-typography-level(60px, 60px, 300, $letter-spacing: -0.5px),
    $headline-3: mat.m2-define-typography-level(48px, 50px, 400),
    $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
    $subtitle-2: mat.m2-define-typography-level(14px, 22px, 500),
    $body-1: mat.m2-define-typography-level(16px, 24px, 400),
    $body-2: mat.m2-define-typography-level(14px, 20px, 400),
    $caption: mat.m2-define-typography-level(12px, 20px, 400),
    $button: mat.m2-define-typography-level(14px, 14px, 500),
);

$my-primary: mat.m2-define-palette(
    (
        50: $brand-primary,
        100: $brand-primary,
        200: $brand-primary,
        300: $brand-primary,
        400: $brand-primary,
        500: $brand-primary,
        600: $brand-primary,
        700: $brand-primary,
        800: $brand-primary,
        900: $brand-primary,
        A100: $brand-primary,
        A200: $brand-primary,
        A400: $brand-primary,
        A700: $brand-primary,
        contrast: (
            50: $icon-grey,
            100: $icon-grey,
            200: $icon-grey,
            300: $icon-grey,
            400: $icon-grey,
            500: $icon-grey,
            600: $icon-grey,
            700: $icon-grey,
            800: $icon-grey,
            900: $icon-grey,
            A100: $icon-grey,
            A200: $icon-grey,
            A400: $icon-grey,
            A700: $icon-grey,
        ),
    )
);

$my-accent: mat.m2-define-palette(
    (
        50: $brand-primary,
        100: $brand-primary,
        200: $brand-primary,
        300: $brand-primary,
        400: $brand-primary,
        500: $brand-primary,
        600: $brand-primary,
        700: $brand-primary,
        800: $brand-primary,
        900: $brand-primary,
        A100: $brand-primary,
        A200: $brand-primary,
        A400: $brand-primary,
        A700: $brand-primary,
        contrast: (
            50: $icon-grey,
            100: $icon-grey,
            200: $icon-grey,
            300: $icon-grey,
            400: $icon-grey,
            500: $icon-grey,
            600: $icon-grey,
            700: $icon-grey,
            800: $icon-grey,
            900: $icon-grey,
            A100: $icon-grey,
            A200: $icon-grey,
            A400: $icon-grey,
            A700: $icon-grey,
        ),
    )
);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        ),
        typography: $custom-typography,
        density: 0,
    )
);

@include mat.core();
@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($custom-typography);

:root {
    --mdc-button-height: 36px;
    --mdc-button-padding: 8px 16px;
    --mdc-button-font-size: 14px;
    --mdc-button-text-transform: uppercase;
    --mdc-button-disabled-opacity: 0.38;
    --mdc-card-border-radius: 10px;
    --mdc-icon-button-size: 48px;
    --mdc-outlined-text-field-outline-width: 0.063rem !important;
    --mdc-outlined-text-field-focus-outline-width: 0.063rem !important;
    --mdc-filled-text-field-focus-active-indicator-height: 0.063rem !important;
    --mdc-outlined-select-hover-outline-color: var(--mdc-select-hover-outline-color);
    --mdc-typography-font-family: "Lato, sans-serif";
}

body {
    font-family: "Lato", sans-serif;
}
