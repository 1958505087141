fx-checkbox.todo-item-green-checkbox {
    .fx-checkbox--checked {
        .fx-checkbox-inner-circle,
        .fx-checkbox-outer-circle {
            background-color: $brand-success;
            border: none;
            opacity: 1;

            &:hover:not(:disabled) {
                opacity: 0.7;
            }
        }
    }
}

fx-checkbox.todo-item-red-checkbox {
    .fx-checkbox--checked {
        .fx-checkbox-inner-circle,
        .fx-checkbox-outer-circle {
            background-color: $brand-danger;
            border: none;
            opacity: 1;

            &:hover:not(:disabled) {
                opacity: 0.7;
            }
        }
        .fx-checkbox-inner-circle {
            &:before {
                content: "\f406";
            }
        }
    }
}
