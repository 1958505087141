mat-form-field.mat-mdc-form-field-type-mat-select {
    &.mat-form-field-appearance-outline {
        .mat-mdc-form-field-infix {
            height: 100%;
            min-height: 100%;
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .mdc-notched-outline {
            .mdc-notched-outline__leading {
                border-radius: 0.75rem 0 0 0.75rem;
                min-width: 0.75rem;
            }
            .mdc-notched-outline__trailing {
                border-radius: 0 0.75rem 0.75rem 0;
            }
        }

        .mdc-text-field {
            .mat-mdc-select-value {
                color: $body-color;
                font-size: px-to-rem(16px);
            }

            .mat-mdc-form-field-infix {
                padding: 9px 0;
                height: 100%;
            }
        }
        .mat-mdc-select-arrow {
            color: $brand-primary;
            svg {
                display: none;
            }
            &::after {
                content: "\f107";
                font-family: "Line Awesome Free";
                font-weight: 900;
                font-size: px-to-rem(16px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $brand-primary;
                -webkit-text-stroke: 1px $brand-primary;
            }
        }
    }
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding: 0;
        min-height: auto;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: $base-04;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
        .mdc-notched-outline
        .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
        .mdc-notched-outline
        .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
        .mdc-notched-outline
        .mdc-notched-outline__trailing {
        border-color: $base-04;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
        border-color: var(--tui-secondary-hover);
    }
}

.mat-mdc-icon-button .mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    display: none;
}

mat-option {
    .mdc-list-item__primary-text {
        color: $body-color;
        display: block;
    }
    .mat-pseudo-checkbox-full {
        color: $icon-grey;
        align-self: flex-start;
        margin-top: 0.2rem;
    }
    &.mdc-list-item {
        align-items: start !important;
        padding-top: 0.5rem;
    }
}
