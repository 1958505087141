.app-container {
    max-width: $app-container-max-width;
    margin: $xs-gutter-size;

    @include breakpoint("mobile") {
        margin: $gutter-size;
    }

    @include breakpoint("tablet") {
        padding: $lg-gutter-size;
        margin: 0 auto;
    }
}
