/* theme variable defaults */

@use "sass:math";

$body-bg: #fafafa !default;
$threads-bg: unset !default;
$threads-message-bg: $threads-bg !default;
$highlight-bg: #f3f5f6 !default;
$dashboard-greeting-background: rgba(0, 173, 240, 0.05);
$dashboard-background: #eef0f1;

$card-box-shadow: 2px 4px 6px -4px rgba(0, 0, 0, 0.2);

$notification-bell-colour: #007fb0 !default;
$unread-notification-card-colour: #00adf0;

$table-label-colour: #32617f !default;

$onboarding-bg-image: unset !default;
$brand-image-width: unset !default;
$menu-brand-image-width: unset !default;

$brand-horizontal-rule: lighten(#9e91a2, 15) !default;
$brand-horizontal-rule-secondary: #c6d4d6 !default;
$brand-border-radius: 6px !default;

$shepard-text-color: white !default;
$shepard-background: black !default;

$font-family-icons: "Line Awesome Free";
$font-family-icons-path: "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
$font-weight-normal: normal;

$brand-success-green: #4c8400 !default;
$brand-progress-blue: #00adf0 !default;
$brand-progress-red: #d70000 !default;

$loader-bg: rgba(0, 0, 0, 0.3);

$onboarding-progress-bar-height-mobile: 5px;
$onboarding-progress-bar-height-desktop: 10px;

$onboarding-modal-desktop-height: 590px !default;
$onboarding-modal-controls-height: 90px !default;

// onboarding colorful pills
$strong-match-pill-color: #03a400 !default;

$banner-bg: rgba(0, 49, 79, 1);

$screen-mobile: 320px !default;
$screen-tablet: 768px !default;
$screen-laptop: 1024px !default;
$screen-laptop-hd: 1190px !default;
$screen-desktop: 1280px !default;
$screen-desktop-hd: 1500px !default;
$screen-hd: 1920px !default;
$grid-breakpoints: (
    mobile: $screen-mobile,
    tablet: $screen-tablet,
    laptop: $screen-laptop,
    laptop-hd: $screen-laptop-hd,
    desktop: $screen-desktop,
    desktop-hd: $screen-desktop-hd,
    hd: $screen-hd,
) !default;

/* theme settings and associated palette import */

// "_theme" is resolved by angular build profile
// stylePreprocessorOptions -> includePaths allows this to be called with the shorter name
@import "_custom-theme";

/* variable overrides */

$asset-path: "./";
$root: "@visoryplatform/fx-ui/styles/";

@import "@visoryplatform/fx-ui/styles/scss/base/imports";
@import "../../../../../packages/portal-ui/projects/portal-ui/assets/variables";
@import "./components/cell";
@import "_classes";

$sidebar-width: 112px;
$sidebar-tablet-width: 300px;
$sidebar-mobile-width: 100%;

$card-border-line-width: 5px;

$app-container-max-width: 2000px !default;

html,
body {
    font-weight: normal;
    color: $body-color;
}

.thread {
    .thread-card-loading .card .card-content {
        display: none;
    }
}

.fx-tabs {
    padding: 1.625rem 1.5rem 0 1.5rem;
    background-color: #fff;
    border-bottom: 1px solid $disabled-color;
    &-item {
        text-transform: none !important;
        font-weight: 700;
    }
}

.draggable {
    cursor: move;
    position: absolute;
    z-index: 9;
}

@mixin ease-in-out($property) {
    transition: $property 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@mixin tooltip($content: "") {
    position: absolute;
    top: -100%;
    padding: math.div($gutter-size, 3);
    background-color: white;
    min-width: 112px;
    font-size: 12px;
    color: #1b1f22;
    content: $content;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #f3f5f6;
    opacity: 1;
}
