.fx-snackbar {
    border-left: 6px solid #bababa;
    &-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    & .fdx-toast-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    &.mat-mdc-snack-bar-container {
        background: white;
        color: black;
        border-radius: 6px;
    }

    &-info {
        border-color: #61abed;
        & .icon {
            color: #61abed;
        }
    }

    &-success {
        border-color: $brand-success;
        & .icon {
            color: $brand-success;
        }
    }

    &-error {
        border-color: $brand-danger;
        & .icon {
            color: $brand-danger;
        }
    }

    &.fdx-toast-title {
        font-weight: 600;
        font-size: 18px;
    }

    & .icon {
        margin-right: 12px;
        font-size: 24px;
    }
}
