.replace-user-controls-col {
    .participant-picker {
        flex-direction: column !important;
        margin-top: -($gutter-size * 1.5);

        &-search {
            width: 100%;
        }

        &-selected-container {
            width: 100%;
            margin: $sm-gutter-size 0 0 0;

            @include breakpoint("tablet") {
                margin-left: 0 !important;
            }
        }
    }
}
