.mat-mdc-form-field.mat-mdc-form-field-type-mat-date-range-input,
.mat-mdc-form-field.mat-mdc-form-field-type-mat-input {
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }
    .mdc-text-field {
        &.mat-mdc-text-field-wrapper {
            padding: 0;
            background-color: transparent;
            .mat-mdc-form-field-flex {
                align-items: center;
            }

            .mdc-line-ripple {
                color: $disabled-color !important;
            }
        }
    }
    .mat-mdc-form-field-subscript-wrapper {
        height: 0;
    }

    i {
        font-size: px-to-rem(24px);
    }

    .mat-mdc-icon-button[disabled] {
        color: $disabled-color;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding: 0;
        min-height: auto;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 0;
        height: 36px;
        width: 36px;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
        border-bottom-color: $disabled-color;
    }
}
