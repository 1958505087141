workflow-details workflow-details-header .workflow-header-content,
nav {
    min-width: 0;
    width: 100%;
    overflow: hidden;

    v-tabs {
        white-space: nowrap !important;
        overflow: auto !important;
        display: block !important;

        .v-tabs {
            flex-wrap: nowrap !important;
        }
    }
}

.account-tabs {
    .fx-tabs {
        padding: 0 $sm-gutter-size;

        @include breakpoint("tablet") {
            padding: 0 $gutter-size;
        }
    }
}
