// add classes here that are theme specific that need to leverage fx-ui components, like breakpoints.

@include breakpoint("laptop") {
    .fx-onboarding {
        &-bg-signup {
            background-image: url(#{$onboarding-bg-signup});
        }
        &-bg-password {
            background-image: url(#{$onboarding-bg-create-password});
        }
        &-bg-login {
            background-image: url(#{$onboarding-bg-login});
        }
        &-bg-verify-email {
            background-image: url(#{$onboarding-bg-verify-email});
        }
        &-bg-verify-mobile {
            background-image: url(#{$onboarding-bg-verify-mobile});
        }
        &-bg-pink {
            background-image: url(#{$onboarding-bg-pink});
            background-size: auto;
        }
    }
}
