/* palette */

$brand-primary: rgba(223, 0, 116, 1); // df0074
$brand-secondary: rgba(0, 49, 79, 1);
$brand-primary-gradient-start: #0094a8;
$brand-primary-gradient-end: #2acad5;
$brand-primary-gradient: linear-gradient(
    187.17deg,
    $brand-primary-gradient-start 31.65%,
    $brand-primary-gradient-end 118.01%
);
$brand-tertiary: #00314f;
$brand-success: #25d366;
$brand-warning: #ffea2c;
$brand-info: #00adf0;
$body-color: #00314f;
$active-step-color: #007cad;
$active-workflow-step-color: #00adf0;
$completed-workflow-line-color: #b5c5e5;
$completed-workflow-body-color: #0075a3;
$table-header-background-color: #f9fafb;

$disabled-color: #ccd6dc;
$body-bg: #fafafa;

$icon-grey: #6b7683;
$icon-brand-warning: #ffc700;
$icon-brand-info: #00adf0;

$text-and-icons-2: #696b7f;
$text-and-icons-4: #4c525d;

$charcoal: #333;
$cancelled-meeting: #be1010;
$error-message: #b51a0d;
$content-hover: #ebeff1;
$delphi-alert: #fe493a;

$brand-colors-hex: map-merge(
    (
        "primary": $brand-primary,
        "secondary": $brand-secondary,
        "tertiary": #d7dee2,
        "pink": #ed4562,
        "blue": #0672bc,
        "dark-cyan-blue-heading": #033f63,
        "dark-cyan-blue-subscript": #1a5071,
        "warning": #ffea2c,
        "info": #80badb,
        "success": #3ab32a,
        "danger": #da3636,
        "dark-danger": #b51a0d,
        "yellow": #f9c215,
        "drab-green": #92bc35,
        "green": #00b25d,
        "dark-green": #057d32,
        "purple": #8e5dc1,
        "grey": #6b7683,
        "light-grey": #ccd6dc,
        "white": #ffffff,
        "black": #000000,
        "cyan": #80d6f7,
        "charcoal": #333333,
        "dark-charcoal": #222222,
    ),
    ()
);

/* Text */
$text-charcoal: #6b7683;
$text-midnight: #00314f;
$text-navy: #004b78;
$text-cerulean: #007fb0;

/* Background, Shadow, Strokes */
$bg-porcelain: #f3f5f6;
$bg-snow: #fafafa;
$bg-silver: #ccd6dc;
$bg-greyscale: #f1f3f9;

/* Base palette */
$base-00: #ffffff;
$base-01: #fafafa;
$base-02: #f1f1f2;
$base-03: #ededed;
$base-04: #d7d7d7;
$base-05: #b0b0b0;
$base-06: #959595;
$base-07: #808080;

/* Text palette */
$text-01: #033f63;
$text-02: #004b78;
$text-03: #4c525d;
$text-04: #6b7683;
$text-05: #838990;
