// mobile borders
.documents {
    fx-table {
        td.mat-mdc-cell {
            &:first-of-type {
                border-top: $table-border;
                border-left: $table-border;
                border-bottom: $table-border;
                border-top-left-radius: $table-border-radius;
                border-bottom-left-radius: $table-border-radius;
                padding-top: 0;
            }

            &:last-of-type {
                border-top: $table-border;
                border-right: $table-border;
                border-bottom: $table-border;
                border-top-right-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }

            &:not(.mobile):first-of-type + .mobile {
                border-top: $table-border;
                padding-top: $sm-gutter-size;
            }

            &.mobile:nth-last-child(2) {
                border-bottom: $table-border;
            }
        }
    }
}

.mdc-data-table__table {
    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
        padding: 0;
        &:first-of-type {
            padding-left: 24px;
        }
    }
}

.requests {
    fx-table {
        td.mat-mdc-cell {
            border-left: $table-border;
            border-right: $table-border;

            &:first-of-type {
                border-top: $table-border;
                border-top-left-radius: $table-border-radius;
                border-top-right-radius: $table-border-radius;
                padding-top: $sm-gutter-size;
            }

            &:last-of-type {
                border-bottom: $table-border;
                border-bottom-left-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }
        }
    }
}

fx-table {
    td.mat-column {
        &-mobile-grid {
            display: grid !important;
            grid-template-columns: 1fr 2fr;

            .row-template-container:not(.column-align-right) {
                justify-content: flex-start;
            }

            @include breakpoint("laptop") {
                display: table-cell !important;

                .row-template-container:not(.column-align-right) {
                    justify-content: flex-start;
                }
            }
        }
    }
}

.threads.threads--with-hover-state {
    fx-table {
        table.mat-mdc-table {
            tr.mat-mdc-row {
                &:hover {
                    box-shadow: $card-box-shadow;

                    td.mat-mdc-cell {
                        .threads-col {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}

.transactions {
    fx-table {
        .header-align-right .mat-sort-header-container {
            flex-direction: row;
        }
    }
}

.threads,
.transactions {
    fx-table {
        .header-align-right .mat-sort-header-container {
            right: -0.5rem;
        }

        table.mat-mdc-table {
            th.mat-mdc-header-cell {
                &:first-of-type {
                    border-top-left-radius: $table-border-radius;
                    border-bottom-left-radius: $table-border-radius;
                }

                &:last-of-type {
                    border-top-right-radius: $table-border-radius;
                    border-bottom-right-radius: $table-border-radius;
                    &.header-align-right .mat-sort-header-container {
                        right: 0;
                    }
                }
            }

            td.mat-mdc-cell {
                border-left: $table-border;
                border-right: $table-border;

                &:first-of-type {
                    border-top: $table-border;
                    border-top-left-radius: $table-border-radius;
                    border-top-right-radius: $table-border-radius;
                    padding-top: $sm-gutter-size;
                }

                &:last-of-type {
                    border-bottom: $table-border;
                    border-bottom-right-radius: $table-border-radius;
                }
            }

            tr.mat-row--has-child {
                td.mat-mdc-cell {
                    border-bottom: unset;
                }

                td.mat-mdc-cell:first-of-type {
                    border-top: $table-border;
                    border-top-left-radius: $table-border-radius;
                    border-top-right-radius: $table-border-radius;
                    padding-top: $sm-gutter-size;
                    color: $brand-primary;
                }

                td.mat-mdc-cell:last-of-type {
                    border-bottom-right-radius: unset;
                    border-bottom-left-radius: unset;
                }
            }

            tr.row-child {
                td.mat-mdc-cell {
                    border-top: unset;
                }

                td.mat-mdc-cell:first-of-type {
                    border-top-left-radius: unset;
                    border-top-right-radius: unset;
                    border-bottom-left-radius: $table-border-radius;
                    border-bottom-right-radius: $table-border-radius;
                }
            }
        }
    }

    &--with-context-menu {
        fx-table {
            table.mat-mdc-table {
                td.mat-mdc-cell {
                    border-right: none;

                    &:last-of-type {
                        border-top-right-radius: $table-border-radius;
                        border-bottom-right-radius: $table-border-radius;
                        border-bottom-left-radius: 0;
                        border-right: $table-border;
                        border-top: $table-border;
                        border-left: 0;
                    }

                    &:first-of-type {
                        border-top-right-radius: 0;
                    }

                    &:nth-last-child(2) {
                        border-bottom: $table-border;
                        border-bottom-left-radius: $table-border-radius;

                        @include breakpoint("laptop") {
                            border-bottom-left-radius: 0;
                        }
                    }
                }
            }
        }
    }
}

.checks {
    table.mat-sort-disabled {
        .mat-mdc-header-row {
            display: none;
        }
    }
}

@include breakpoint("laptop") {
    .requests {
        fx-table {
            td.mat-mdc-cell {
                border-left: unset;
                border-right: unset;

                &:first-of-type {
                    border-left: $table-border;
                    border-bottom: $table-border;
                    border-bottom-left-radius: $table-border-radius;
                }

                &:last-of-type {
                    border-top: $table-border;
                    border-right: $table-border;
                    border-top-right-radius: $table-border-radius;
                }
            }
        }
    }

    .threads,
    .transactions {
        fx-table {
            table.mat-mdc-table {
                td.mat-mdc-cell {
                    border-left: unset;
                    border-right: unset;
                    border-radius: unset;

                    &:first-of-type {
                        border-left: $table-border;
                        border-top: $table-border;
                        border-bottom: $table-border;
                        border-top-left-radius: $table-border-radius;
                        border-bottom-left-radius: $table-border-radius;
                        border-top-right-radius: unset;
                    }

                    &:last-of-type {
                        border-right: $table-border;
                        border-top: $table-border;
                        border-bottom: $table-border;
                        border-top-right-radius: $table-border-radius;
                        border-bottom-right-radius: $table-border-radius;
                    }
                }

                hr {
                    margin: $xs-gutter-size;
                }

                tr.mat-row--has-child {
                    td.mat-mdc-cell {
                        border-left: unset;
                        border-right: unset;
                        border-bottom: unset;
                    }

                    td.mat-mdc-cell:first-of-type {
                        border-left: $table-border;
                        border-bottom-left-radius: unset;
                        border-top-right-radius: unset;
                    }

                    td.mat-mdc-cell:last-of-type {
                        border-right: $table-border;
                        border-bottom: unset;
                        border-bottom-right-radius: unset;
                        border-top-left-radius: unset;
                    }
                }

                tr.row-child {
                    td.mat-mdc-cell {
                        border-left: $table-border;
                        border-right: $table-border;
                        border-top: unset;
                    }

                    td.mat-mdc-cell:first-of-type {
                        border-top: unset;
                        border-top-left-radius: unset;
                    }

                    td.mat-mdc-cell:last-of-type {
                        border-top-right-radius: unset;
                    }
                }
            }
        }
    }
}

// Global styling for fx-table cell labels
.mobile-cell-label {
    display: inline;
    padding-right: math.div($gutter-size, 3);
    font-size: px-to-rem(12px);
    color: $icon-grey;
    font-weight: 700;
    align-self: center;
    @include breakpoint("laptop") {
        display: none;
    }
}
