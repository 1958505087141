@use "sass:math";

@import "./src/assets/scss/theme";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

quill-editor,
quill-view,
quill-view-html {
    width: 100%;
    .ql-container {
        box-sizing: border-box;
        font-family: $font-family-body;
        font-size: 1rem;
        height: 100%;
        margin: 0px;
        position: relative;
    }
    .ql-container.ql-snow {
        border: unset;
        overflow: auto;
        height: 100%;
    }
    .ql-container {
        box-sizing: border-box;
        font-family: $font-family-body;
        font-size: 1rem;
        height: 100%;
        margin: 0px;
        position: relative;
    }
    .ql-editor {
        box-sizing: border-box;
        font-size: px-to-rem(16px);
        line-height: 1.42;
        height: 100%;
        outline: none;
        overflow-y: auto;
        overflow-wrap: anywhere;
        padding: unset;
        tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-word;
    }
    .ql-toolbar.ql-snow {
        border: unset;
        box-sizing: border-box;
        font-family: $font-family-body;
        padding: unset;
        padding-right: $xs-gutter-size;
        padding-bottom: math.div($gutter-size, 3);
        padding-left: unset;
        position: relative;
        right: 8px;
    }
    .ql-toolbar.ql-snow .ql-formats {
        margin-right: unset;
    }
    .ql-snow .ql-editor a {
        text-decoration: none;
        color: $brand-primary;
    }
}

quill-view-html {
    .ql-editor p {
        height: auto;
    }
}
.reply-details-message {
    quill-view,
    quill-view-html {
        .ql-container.ql-snow {
            border: unset;
            overflow: hidden;
        }
        .ql-editor {
            padding-bottom: unset;
        }
    }
}
.card-description {
    quill-view,
    quill-view-html {
        .ql-container.ql-snow {
            border: unset;
            overflow: hidden;
        }
    }
}

.search-card {
    quill-view .ql-editor {
        font-size: px-to-rem(14px);

        > * {
            cursor: pointer !important;
        }
    }
}

quill-editor {
    .ql-editor.ql-blank::before {
        left: 0;
        font-style: normal;
    }
}

quill-editor.editor-toolbar {
    &--hide-toolbar {
        .ql-toolbar.ql-snow {
            display: none;
        }
    }
    &--show-toolbar {
        .ql-toolbar.ql-snow {
            border: unset;
            box-sizing: border-box;
            font-family: $font-family-body;
            padding: unset;
            padding-right: $xs-gutter-size;
            padding-bottom: math.div($gutter-size, 3);
            padding-left: unset;
            position: relative;
            right: 8px;
        }
    }
}

.quill-modal .ql-container {
    max-height: 46vh;
    min-height: 10vh;
}

@media only screen and (max-width: 768px) {
    .quill-modal .ql-container {
        max-height: 20vh;
        min-height: 5vh;
    }
}

// Mentions
.ql-mention-header {
    padding: $xs-gutter-size $sm-gutter-size;
    font-size: px-to-rem(12px);
}

.ql-mention-list-container {
    background-color: $white;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    transform: translateY(-2px);
    margin-top: 0;
    border-radius: px-to-rem(4px);
    z-index: 100;
}

.ql-mention-list {
    list-style-type: none;
    max-height: px-to-rem(200px);
    max-width: px-to-rem(250px);
    overflow-y: auto;

    &-item {
        padding: $gutter-size;
    }

    .ql-mention-list-item {
        cursor: pointer;
        padding: $sm-gutter-size;
        &.selected {
            color: $brand-primary;
            background-color: rgba($unread-notification-card-colour, 0.05);
        }
    }
}

.ql-mention-item {
    display: flex;
    align-items: center;
    .ql-mention-item-avatar {
        margin-right: $sm-gutter-size;
        min-width: px-to-rem(42px);
        min-height: px-to-rem(42px);
    }
    .ql-mention-item-name {
        display: flex;
        flex-direction: column;
    }
    .ql-mention-item-title {
        font-size: px-to-rem(12px);
        color: $icon-grey;
    }
}

span.mention {
    font-weight: 600;
    color: $brand-primary;
    cursor: pointer;
    white-space: nowrap;
}
