@import "./src/assets/scss/theme";

@import "./layout/index";
@import "./components/index";
@import "./material-overrides/components/index";

@import "https://fonts.googleapis.com/css?family=Lato:300,400,600,700|Philosopher:400,700&display=swap";

/* Import portal-ui styles */
@import "@visoryplatform/portal-ui/assets/variables.scss";
@import "@visoryplatform/portal-ui/assets/styles.scss";
