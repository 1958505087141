.fx-error-modal {
    &-dialog {
        width: 100vw;
        height: 100%;
        .mat-mdc-dialog-container {
            border-radius: 0;
            align-items: center;
            .mat-mdc-dialog-surface {
                padding: $gutter-size 0;
                display: flex;
            }
        }

        &-image {
            margin-bottom: $gutter-size;
            min-height: 295px;
            width: 100%;
            background: url(/#{$error-bg}) center center no-repeat;
            background-size: contain;
        }
        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h1 {
                font-size: 32px;
                margin: $gutter-size 0;
                font-weight: normal;
            }
            p {
                margin-bottom: $gutter-size;
            }
        }
    }
}

@include breakpoint("tablet") {
    .fx-error-modal {
        &-dialog {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 586px;
            height: auto;
            mat-dialog-container {
                border-radius: 4px;
            }
        }
    }
}
