@use "sass:math";

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
}

// styling for all centered modals
.centered-modal {
    &.cdk-overlay-pane {
        max-height: 100%;
        max-width: 100% !important;
        width: 100%;
        overflow-y: auto;
        @include breakpoint("tablet") {
            width: px-to-rem(420px);
            height: initial;
        }
    }
    mat-dialog-container {
        padding: 0;
        border-radius: 0;
        @include breakpoint("tablet") {
            border-radius: 5px;
        }
    }

    &--two-columns {
        @include breakpoint("tablet") {
            &.cdk-overlay-pane {
                width: unset;
                max-width: 80%;
                height: initial;
            }
            mat-dialog-container {
                width: 80vw;
                margin: auto;
            }
        }
    }
}

.modal-container {
    color: $body-color;
    position: fixed;
    top: 20%;
    bottom: 20%;
    left: 0;
    right: 0;
    width: 80%;
    height: 80vh;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: none;
    background: transparent;
    &-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        cursor: pointer;
    }
}

.mat-dialog-no-styling {
    .mat-mdc-dialog-container {
        color: $body-color;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        position: relative;
    }
    .mat-mdc-dialog-content {
        margin: 0;
    }
}

.brand-font-color {
    .mat-mdc-dialog-container {
        color: $body-color;
    }
}

.centre-toast-modal {
    @extend .mat-dialog-no-styling;
    position: absolute !important; // override's MatDialog inline static position
    top: 15%;
    overflow: visible;
    border-radius: 10px;
}
.centre-solid-background-modal-timeline {
    @extend .mat-dialog-no-styling;
    .mat-mdc-dialog-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        @include breakpoint("tablet") {
            height: auto;
            position: static;
            border-radius: 10px;
            max-width: px-to-rem(890px);
        }
    }
}

.calendar-details-modal {
    @extend .mat-dialog-no-styling;
    .mat-mdc-dialog-container {
        padding: $gutter-size * 0.8;
    }

    border-radius: 6px;
    width: 85vw;
    height: 100vw;

    @include breakpoint("tablet") {
        width: 54vw;
        height: 60vh;
    }
}

.create-payment-modal {
    &.centered-modal.cdk-overlay-pane {
        width: 100vw;
        @include breakpoint("tablet") {
            width: initial;
        }
    }

    .mat-mdc-dialog-container {
        max-width: 100vw;
    }
}

.document-preview-modal {
    max-width: 100vw;
    @include breakpoint("laptop") {
        max-width: 80vw;
        padding: $gutter-size;
    }
    .mat-mdc-dialog-container {
        box-shadow: 0px 0px 4px rgba($brand-secondary, 30%);
        width: 100vw;
        height: 100vh;

        @include breakpoint("laptop") {
            max-height: 80vh;
            max-width: 80vw;
            width: unset;
        }
    }
}

.video-chat-modal-timeline {
    @extend .mat-dialog-no-styling;
    position: absolute !important; // override's MatDialog inline static position
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .mat-mdc-dialog-container {
        overflow: hidden;
    }

    &--maximised {
        transform: none !important;
    }

    &--minimised {
        height: 30vw;
        width: 50vw;
        // hide self view only if another user is connected
        .has-subscribers {
            .draggable {
                display: none !important;
            }
        }
    }
}

.threads-add-participants-modal {
    position: absolute !important;
    width: 80vw;
    max-height: px-to-rem(250px);
    margin: 0 auto;
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        overflow: visible;
    }
}

@include breakpoint("tablet") {
    .video-chat-modal-timeline {
        &--minimised {
            height: 18vw;
            width: 32vw;
        }
    }

    .threads-add-participants-modal {
        position: absolute !important;
        top: px-to-rem(130px);
        right: px-to-rem(10px);
        width: px-to-rem(500px);
        max-height: px-to-rem(250px);

        &.timeline-participants {
            top: px-to-rem(250px);
            left: px-to-rem(110px);
            right: unset;
        }
    }
}

.threads-sidebar {
    width: 100vw;
    &-inner-close-modal {
        width: px-to-rem(640px);
        margin-top: auto;
        overflow-y: auto;
        max-width: 100vw !important;
        @include breakpoint("tablet") {
            margin-top: initial;
        }
        .mat-mdc-dialog-container {
            padding: 0;
            @include breakpoint("tablet") {
                width: 80%;
                margin: 50px auto;
                position: relative;
                border-radius: 10px;
            }
        }
    }
    &-inner-modal {
        .mat-mdc-dialog-container {
            width: 80%;
            margin: 0 auto;
            position: relative;
        }
    }
}

.fx-l-modal {
    position: relative;
    padding-top: px-to-rem(108px);

    &--has-sub-header {
        padding-top: px-to-rem(146px);
    }

    &--has-description {
        padding-top: px-to-rem(132px);
    }

    &--sticky-footer {
        padding-bottom: px-to-rem(150px);
    }

    &-sub-header {
        font-size: px-to-rem(12px);
        margin-top: $sm-gutter-size;
        margin-bottom: math.div($gutter-size, 3);
        text-transform: capitalize;
    }

    &-header {
        padding: $gutter-size;
        background-color: $white;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

        &--no-title {
            min-height: px-to-rem(108px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &--inner-modal {
            box-shadow: none;
            background: none;
        }

        &-icon {
            color: $brand-primary;
            cursor: pointer;
            -webkit-appearance: none;
        }

        h2 {
            font-size: px-to-rem(24px);
            color: $brand-secondary;
            font-weight: $font-weight-normal;
        }
    }

    &-content {
        padding: $gutter-size;
        position: relative;

        &--no-gutters {
            padding: 0;
        }

        loader-icon {
            position: static;
        }
    }

    &-footer {
        padding: 0 $gutter-size $gutter-size;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 2;

        &:after {
            content: "";
            display: block;
            height: px-to-rem(150px);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: -1;
            background: url("../../images/gradient.png") bottom;
            pointer-events: none;
        }
    }
}

@include breakpoint("laptop") {
    .threads-sidebar {
        position: relative;
        width: px-to-rem(640px);
        margin-right: 0;
        margin-left: auto;

        .fx-l-modal {
            &-footer,
            &-header {
                width: px-to-rem(640px);
            }

            &--has-sub-header {
                padding-top: px-to-rem(108px);
            }

            &-sub-header {
                display: none;
            }
        }

        &.threads-sidebar-inner-close-modal {
            mat-dialog-container {
                max-width: px-to-rem(440px);
            }
        }
    }
}

.mat-dialog-no-container {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: unset;
        box-shadow: unset;
        background: unset;
    }
}

.mat-dialog-overflow {
    .mat-mdc-dialog-container {
        overflow: visible;
    }
}
