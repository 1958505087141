fx-avatar.fx-avatar--resizable {
    display: block;
    width: 100%;
    height: 100%;
    .fx-avatar {
        width: 100%;
        height: 100%;
    }
}

.message-preview-header-avatar {
    .fx-avatar {
        width: 32px;
        height: 32px;
    }
}
