.request-common {
    label,
    &-label {
        color: $icon-grey;
        font-size: px-to-rem(16px);
        display: flex;
    }

    &-group {
        margin-bottom: $gutter-size;
        margin-top: $gutter-size;
        &--narrow {
            request-reply,
            request-view-reply {
                display: block;
            }
        }
    }

    &-complete-all {
        padding-top: $gutter-size;
        button {
            font-weight: 600;
            cursor: pointer;
            color: $brand-primary;
        }
    }

    &-complete-all--disabled {
        padding-top: $gutter-size;
        button {
            font-weight: 600;
            opacity: 50%;
            color: $brand-primary;
            cursor: not-allowed;
        }
    }

    &-thread-info {
        color: $body-color;
        &-title {
            font-size: px-to-rem(16px);
            font-weight: 600;
        }
        &-subhead {
            font-size: px-to-rem(16px);
        }
        margin-bottom: $gutter-size;
    }

    &-request-info {
        display: flex;
        form {
            display: flex;
        }
        &-fields {
            display: flex;
            flex-direction: column;
        }
    }

    &-secondary {
        &-header {
            h1 {
                font-size: 24px;
                margin: 0 0 $sm-gutter-size 0;
            }
        }
        &-sub-header {
            font-size: px-to-rem(14px);
            color: $icon-grey;
            margin-bottom: $gutter-size;
            display: flex;
            justify-content: space-between;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;

        &-item {
            margin-bottom: $sm-gutter-size;

            @include breakpoint("tablet") {
                display: flex;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            max-width: 140px;
            flex-grow: 1;
            padding-right: $gutter-size;
            color: $icon-grey;
            margin-bottom: math.div($gutter-size, 3);
            @include breakpoint("tablet") {
                display: flex;
                margin-bottom: 0;
            }
        }

        &-items {
            > div {
                flex-grow: 1;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    &-file {
        display: flex;
        align-items: center;
        cursor: pointer;
        &-icon {
            margin-right: math.div($gutter-size, 3);
            color: $brand-primary;
        }
        &-name {
            text-decoration: underline;
            cursor: pointer;
            max-width: 60vw;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 24px;
            white-space: nowrap;

            @include breakpoint("tablet") {
                overflow: visible;
                white-space: unset;
                max-width: unset;
                height: unset;
            }
        }
    }

    &-created-by {
        span {
            color: $brand-secondary;
        }
    }

    &-todo-list {
        margin-bottom: $gutter-size;
    }

    &-todo {
        $this: &;
        flex-grow: 1;
        &-details {
            display: flex;
            flex-grow: 1;
            align-items: flex-start;
            &--checked {
                color: lighten($icon-grey, 20);
                text-decoration: line-through;
            }
            &-remove {
                cursor: pointer;
                i {
                    color: $brand-primary;
                }
                &:focus {
                    i {
                        text-decoration: underline;
                    }
                }
            }
            &-field {
                min-height: 20px;
                border: none;
                color: $body-color;
                flex-grow: 1;
                margin-right: $sm-gutter-size;
                background: none;
                opacity: initial;

                &:focus {
                    outline: none;
                }
            }
        }
        &-add-item {
            margin-left: 2rem;
            color: $brand-primary;
            font-weight: 600;
            font-size: px-to-rem(14px);
            cursor: pointer;
            &:focus {
                text-decoration: underline;
            }
            &--disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
        &-error {
            margin: 0 0 $sm-gutter-size 0;
            padding-left: 2rem;
        }
    }

    .fx-form-validation {
        color: $brand-danger;
    }
}
