// add variables here. add theme specific classes to _classes.scss
@import "sigma-palette.scss";
$navy: #004b78;
$ocean: #00adf0;

$font-family-body: "Lato", sans-serif;
$font-family-heading: "Philosopher", sans-serif;
$brand-dark-button-type: "secondary";
$brand-dark-accent: $brand-secondary;
$brand-thread-card-body: $body-color;
$brand-image: "/assets/images/sigma-brand.svg";
$brand-image-width: 125px;
$ai-logo-image: "/assets/images/visory/delphi-ai-small.svg";
$ai-brand-image: "/assets/images/visory/delphi-ai-alt.svg";
$menu-brand-image: "/assets/images/visory/logo-pink.svg";
$header-height: 50px;
$header-height-mobile: 57px;
$banner-height: 72px;
$banner-height-mobile: 114px;
$header-with-banner: $header-height + $banner-height;
$menu-brand-image-width: 72px;
$threads-bg: #ebeff1;
$brand-content-bg: #f3f5f6;
$card-border-color: #bac3c7;
$form-dark-button-outlined: true;
$form-dark-button-type: "primary";
$header-bgColor: white;
$card-border-line-width: 8px;
$shepard-text-color: $body-color;
$shepard-background: white;

$strong-match-pill-color: #03a400;

$brand-loader-image: "/assets/images/sigma-loader.svg";

$onboarding-bg-signup: "/assets/images/visory-signup.svg";
$onboarding-bg-create-password: "/assets/images/visory-create-password.svg";
$onboarding-bg-login: "/assets/images/visory-login.svg";
$onboarding-bg-verify-email: "/assets/images/visory-verify-email.svg";
$onboarding-bg-verify-mobile: "/assets/images/visory-verify-mobile.svg";
$onboarding-bg-pink: "/assets/images/onboarding-bg-pink.svg";

$system-avatar: "/assets/images/sigma-system-avatar.svg";
$system-avatar-size: 21px;

$error-bg: "assets/images/errors/sigma-error-something-went-wrong.svg";

$onboarding-modal-desktop-height: 590px;
$onboarding-modal-controls-height: 90px;

$notification-bell-colour: #df1e0e;
$unread-notification-colour: #00adf0;

$table-border: 1px solid $disabled-color;
$table-border-radius: 5px;

$threads-task-text: $navy;
$threads-task-icon: $ocean;

$orange: #ed6300;
$yellow: #f0c419;
$dark-green: #057c32;
$red: #df1e0e;
$pink: #fdcece;
$faded-pink: #ffd8d8;
