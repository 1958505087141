mat-drawer.mat-drawer {
    width: 100%;
    color: $body-color;
}

mat-drawer-content.mat-drawer-content {
    z-index: auto;
}

mat-drawer-container.mat-drawer-container {
    color: $body-color;
    height: 100%;
    z-index: auto;
}

.mat-drawer.mat-drawer-end {
    width: 100%;
}
