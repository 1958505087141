/**
    Common variables
 */
$gutter-size: 1.5rem;
$xs-gutter-size: $gutter-size * 0.25;
$sm-gutter-size: $gutter-size * 0.5;
$md-gutter-size: $gutter-size * 0.75;
$lg-gutter-size: $gutter-size * 2;
$xl-gutter-size: $gutter-size * 4;

/**
    Button variables
 */
$button-border-size: 1px;
$button-border-radius: 6px;
$button-font-size: 1rem;
$select-button-label-font-size: 0.8rem;
$select-button-label-line-height: 1rem;
