@use "sass:math";

$ionicons-font-path: "~ionicons/dist/fonts";
@use "../../../node_modules/ionicons/dist/scss/ionicons";

@import "./src/assets/scss/mixins/error-message.scss";
@import "./assets/scss/global";
@import "./assets/scss/theme";
@import "@visoryplatform/fx-ui/styles/scss/index";
@import "./assets/scss/material-overrides/theme/material-theme-custom";
@import "./assets/scss/notifications-frontend/notifications-frontend";
@import "@angular/cdk/overlay-prebuilt.css";

body {
    --visory-spacing-form-labels: #{math.div($gutter-size, 3)};
}

tui-dropdown-host {
    z-index: unset !important;

    tui-dropdown {
        z-index: 10000 !important;
    }
}

.fx-form-validation {
    color: $cancelled-meeting;
}

//TODO: add to fx-ui
.fx-accordion-content--is-visible.fx-accordion-content {
    overflow: visible;
}

.fx-form-icon + input {
    padding-left: 1.5em;
}

.fx-btn {
    &--tertiary,
    &--secondary,
    &--primary {
        padding-inline: 1em;
        &-ghost {
            padding-inline: 1em;
            border-color: $bg-porcelain;
            background-color: $base-00;
            color: $brand-primary;
            &:hover:not(:disabled) {
                color: $brand-primary;
                background-color: $base-03;
                border-color: $brand-primary;
            }
        }
    }

    &--stacked {
        display: flex;
        align-items: center;
        color: $brand-primary;

        i {
            font-size: px-to-rem(22px);
            padding-right: $xs-gutter-size;
        }

        &:hover:not(:disabled) {
            border-radius: px-to-rem(5px);
            background-color: transparentize($brand-primary, 0.94);
            cursor: pointer;
        }

        &:disabled {
            color: $brand-secondary;
            cursor: default;
        }

        @include breakpoint("laptop") {
            width: px-to-rem(100px);
            padding: $sm-gutter-size 0;
            flex-direction: column;

            i {
                padding: 0 $sm-gutter-size $sm-gutter-size $sm-gutter-size;
            }
        }

        @include breakpoint("desktop") {
            width: px-to-rem(100px);
            padding: $sm-gutter-size 0;
            flex-direction: column;

            i {
                padding: 0 $sm-gutter-size $sm-gutter-size $sm-gutter-size;
            }
        }
    }

    &--primary-outlined:disabled:hover {
        background-color: initial;
        color: $brand-primary;
    }

    &:disabled {
        cursor: default;
    }
}

.system-avatar .fx-avatar--secondary {
    border: none;
    background-color: transparent;
}

.fx-threads,
.fx-threads-messages {
    .card {
        margin-bottom: $xs-gutter-size;
    }
}

.fx-chip {
    background-color: $brand-secondary;
}

.shepherd {
    &-content {
        color: $shepard-text-color;
        background-color: $shepard-background;
    }

    &-button {
        &:only-child {
            width: 70%;
            margin-left: 15% !important;
            margin-right: 15% !important;
        }

        &:hover {
            //Override shepherd.js' default style
            background-color: $brand-primary !important;
        }
    }

    &-footer {
        padding: 0 0.75rem 36px;
    }

    &-text {
        overflow: hidden;
        max-height: 60vh;
    }
}

.tour {
    &-header {
        margin: 24px 24px 28px 24px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            padding-left: 8px;
            height: 24px;
        }
    }

    &-content {
        color: $shepard-text-color;

        p {
            padding: 0 18px 12px 18px;
        }
    }

    &-body {
        text-align: center;
    }

    &-logo {
        content: url(#{$brand-image});

        &-align-center {
            margin-bottom: -6px;
        }
    }

    &-tagline {
        height: 56px;
        margin: 16px;
    }
}

.ai-icon {
    background-image: url($ai-logo-image);
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;

    background-size: contain;
    top: 5px;
}

thread-type-badge,
v-tab {
    .ai-icon {
        width: 19px;
        height: 19px;
        top: 0;
    }
}

.ai-brand {
    background-image: url($ai-brand-image);
    width: 24px;
    height: 24px;
    display: inline-block;

    background-size: contain;
}

.transparent-overlay-background {
    background-color: transparent;
}

.v-select-container {
    margin: $gutter-size * 0.2 0 $md-gutter-size 0;
}

* {
    $scroll-bg: #fff;
    $thumb-bg: #e5e5e5;
    scrollbar-color: $thumb-bg $scroll-bg;

    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: $scroll-bg;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-bg;
        border-radius: 16px;
        border: 4px solid $scroll-bg;
    }
}

.tab-section {
    padding: $gutter-size;
    margin: 0;

    @include breakpoint("tablet") {
        padding: $lg-gutter-size;
        margin: $gutter-size;
    }
}
