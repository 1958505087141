mat-button-toggle-group.mat-button-toggle-group {
    border: 1px solid $brand-primary;
    border-radius: 20px;
    mat-button-toggle.mat-button-toggle {
        text-align: center;
        background-color: $white;
        white-space: nowrap;
        vertical-align: middle;
        color: $brand-primary;
        height: 28px;
        .mat-button-toggle-label-content {
            line-height: px-to-rem(28px);
            padding: 0 $gutter-size !important;
        }
    }
    .mat-button-toggle-checked {
        background-color: $brand-primary !important;
        color: $white !important;
        &:hover {
            background-color: darken($brand-primary, 5%);
        }
    }
}
