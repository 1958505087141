@use "sass:math";
@import "@visoryplatform/fx-ui/styles/scss/base/imports";

$notification-hover: #f1f2f4 !default;
$read-marker: #007fb0 !default;
$notification-height: 100px !default;
$text-and-icons-2: #696b7f !default;
$text-and-icons-4: #4c525d !default;

.recent-notifications {
    &-header {
        font-size: px-to-rem(24px);
        font-weight: 700;
        padding: $sm-gutter-size $gutter-size;
        border-bottom: 1px solid $notification-hover;

        &-main {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-title {
                display: flex;
                align-items: center;
                font-size: px-to-rem(20px);
                color: $navy;
                i {
                    margin-right: ($gutter-size * 0.33);
                    font-size: px-to-rem(28px);
                }
            }

            fx-context-menu {
                color: $brand-primary;
            }
        }
    }

    &-filters {
        margin: $sm-gutter-size 0 0 $xs-gutter-size;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: px-to-rem(14px);
        font-weight: normal;

        v-toggle,
        p {
            color: $text-charcoal;
        }

        &-mark-all {
            color: $brand-primary;
            transition: filter 0.3s ease;
            cursor: pointer;

            i {
                margin-right: $xs-gutter-size;
                font-weight: bold;
            }

            &:disabled {
                color: $text-charcoal;
                cursor: default;
            }
        }
    }

    &-notifications {
        margin-bottom: $sm-gutter-size;
        &.scroll {
            overflow-y: auto;
        }
    }

    &-none {
        text-align: center;
        padding: $gutter-size;
        flex-grow: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    notification {
        display: block;
        height: $notification-height;
        border-bottom: 1px solid $notification-hover;

        &:last-child {
            border-bottom: none;
        }

        .notification {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            padding: 0 $xs-gutter-size 0 $xs-gutter-size;
            border-left: 0.3rem solid transparent;
            position: relative;

            @include breakpoint("tablet") {
                padding: 0 $sm-gutter-size 0 $sm-gutter-size;
            }

            &:hover {
                background-color: $notification-hover;
            }

            &-body-header-info,
            &-controls-time-ago {
                color: $text-and-icons-2;
            }
            &-label,
            &-summary {
                color: $text-and-icons-4;
            }

            &--unread {
                border-left: 0.3rem solid $unread-notification-card-colour;
                background-color: rgba($unread-notification-card-colour, 0.05);

                .notification {
                    &-label,
                    &-summary {
                        color: $body-color;
                    }

                    &-controls-time-ago {
                        color: $text-and-icons-4;
                    }
                }
            }

            &-body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: hidden;
                cursor: pointer;

                &-menu {
                    position: absolute;
                    top: $sm-gutter-size;
                    right: $sm-gutter-size;
                    fx-context-menu {
                        color: $brand-primary;
                    }
                }

                &-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: $xs-gutter-size;
                    &-info {
                        display: flex;
                        align-items: center;
                    }
                }

                &-description {
                    display: flex;
                    align-items: center;
                    &-info {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        padding-left: math.div($gutter-size, 3);
                        overflow: hidden;
                        &-message {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
            }

            &-divider {
                padding: 0 math.div($gutter-size, 4);
            }

            &-thread-name,
            &-account-name,
            &-divider {
                font-size: px-to-rem(12px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
            }

            &-controls {
                &-time-ago {
                    font-size: px-to-rem(12px);
                    text-align: right;
                    white-space: nowrap;
                }
            }

            &-label {
                word-break: break-word;
                line-height: 1.4;
                overflow: hidden;
                font-size: px-to-rem(14px);
                padding-right: $gutter-size;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;

                &--single-line {
                    white-space: nowrap;
                    display: flex;
                    strong {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-right: 3px;
                        min-width: 20px;
                    }
                }
            }
            &-summary {
                font-size: px-to-rem(14px);
                line-height: 1.3;
                margin-top: $gutter-size * 0.125;
                padding-right: $gutter-size;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.recent-notifications-viewport {
    .cdk-virtual-scroll-content-wrapper {
        width: 100%;
    }
}
