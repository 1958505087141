.thread-card-error {
    margin-top: $gutter-size;
    @include errorMessage;
}

.thread-card {
    .card {
        &-description blockquote {
            border-left: px-to-rem(5px) solid lighten($delphi-alert, 30) !important;
            padding-left: $xs-gutter-size !important;
        }
    }

    &.thread-card--referenced {
        .card {
            &-description blockquote {
                border-left: px-to-rem(5px) solid $base-04 !important;
            }
        }
    }
}
