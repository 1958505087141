mat-calendar {
    .mat-calendar-body-cell-content,
    .mdc-button__label {
        color: $body-color;
    }

    .mat-calendar-body-cell-content {
        font-size: px-to-rem(13px);
    }

    .mdc-button__label,
    .mat-calendar-body-label {
        font-size: px-to-rem(14px);
    }

    .mat-calendar-content tr th,
    .mat-calendar-body-label,
    .mat-calendar-controls .mat-calendar-next-button,
    .mat-calendar-controls .mat-calendar-previous-button {
        color: $text-01;
    }

    .mat-calendar-content tr th {
        font-size: px-to-rem(11px);
    }

    .mat-calendar-body-selected {
        color: $white !important;
    }

    .mat-calendar-body-today {
        &.mat-calendar-body-selected {
            box-shadow: none !important;
        }

        &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            color: $body-color !important;
        }
    }
}
